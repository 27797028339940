html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  margin: 0px;
  font-size: 100%;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  }

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
  border:1px solid black;
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}

.wrapper {
  background-color: #fff;
  color:#000;
}

.wrapper {
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
    "header"
    "sidebar"
    "content"    
    "footer";
}

@media only screen and (min-width: 500px) {
  .wrapper {
    grid-template-columns: 20% auto;
    grid-template-areas:
      "header   header"
      "sidebar  content"    
      "footer   footer";
  
  }
}

@media only screen and (min-width: 600px) {
  .wrapper {
    grid-gap: 5px;
    grid-template-columns:180px auto auto;
    grid-template-rows: 80px auto 60px;
    grid-template-areas:
      "header  header  header"
      "sidebar content content"
      "footer  footer  footer";
   
    /* max-width: 600px; */
    /* min-height: 1000px; */
  }
}

.box {
  /* background-color: #444;
  color: #fff; */
  border-radius: 5px;
  padding: 10px;
  /* font-size: 150%;*/
} 

.header,
.footer {
  background-color: #ccc;
}
.sidebar{
  background-color: #ccc;
  color:black;
}



ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #f1f1f1;
}

li a.active {
  background-color: #4CAF50;
  color: white;
}

li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}


li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

a[data-id='4'] {
  background-color: #4CAF50;
  color: white;
}



