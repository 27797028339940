/* Override some Bootstrap pagination styles */
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  /* background-color: #e3e7eb !important; */
  background-color: #aaa !important;
  border-color: #ced4da !important;
  z-index: 0;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 0.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;

  /* font-weight: 900;
  font-size: 1rem; */
}
ul.pagination a.page-link:hover {
  /* background-color: #f4f4f4; */
  background-color: #aaa;
  background-color: #333;
  border-color: #333;
  color: #eee;
}

ul.pagination .tips  {  
  padding: 0.75rem 0.2rem;
  min-width: 0.5rem;
  text-align: center;  
}

ul.pagination input  {
  width: 3.1rem;  
}




