input {
  box-sizing: border-box;
}

select {
  padding: 2px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}

textarea {
  width: 80%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}
input:focus {
  border: 3px solid #555;
  
}


input[type=text]:focus {
  border: 3px solid #555;
}

.label {
  padding-top: 5px;
  padding-right: 10px;
  width: 10%;  
  height: 36px; 
  text-align: right;


}

.select-small
{
  width: 50%; 
  height: 36px;  
  display: inline-block;
  
}
.select-big 
{
  width: 75%;
  height: atuo; 
  display: inline-block;
  
}
.combox-big
{
 
  height: 36px; 
  display: inline-block;
  
}
/* .combox-big input
{
  width: 800px;  
 
} */
.half{
  width:30%;
  height: 36px; 
  display: inline-block;
}
.line{
  width: 70%;
  height: 36px; 
  display: inline-block;
}

.error{
  text-align: left;
  margin-top: -20px;
  margin-left: 15%;
  color: red;
}

/* input  {
  width: 80%;
  height:40px;
  margin: 5px;
  padding: 5px;

} */
