.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
  background-color:grey;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (max-width:500px){
  body{
    color:red;
  }
}

.top1
{
  position: sticky;
  top: 0;
  background-color: #563d7c;
  z-index: 100;
}
.top2
{
  position: sticky;
  top: 300;
  background-color:darkslategray;
  z-index: 100;
}


